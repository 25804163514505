import advertmingoLogo from "./advertmingo-logo.svg";
import cardImg from "./card.png";
import myAdvertmingo from "./my-advertmingo.svg";
import globe from "./globe.svg";
import languageArrow from "./language-arrow.svg";
import check from "./check.svg";
import close from "./close.svg";
import back from "./back.svg";
import about from "./about.jpg";
import tuneitconLogo from "./tuneitcon-logo.svg";
import tuneitcon from "./tuneitcon.png";
import carouselNav from "./carousel-nav.svg";
import whoIsFor from "./whoisfor.jpg";
import whoIsForTop from "./whoisfortop.jpg";
import HowItWorks from "./howitworks.jpg";
import errorIcon from "./error.svg";
import warningIcon from "./warning.svg";
import infoIcon from "./info.svg";
import successIcon from "./success.svg";
import loginBg from "./login-bg.jpg";
import signOut from "./signout.svg";
import perValidationEmail from "./email.jpg";
import validationSuccess from "./success.jpg";
import underConstruction from "./under-construction.jpg";

export {
  advertmingoLogo,
  cardImg,
  myAdvertmingo,
  globe,
  languageArrow,
  check,
  close,
  back,
  about,
  tuneitconLogo,
  tuneitcon,
  carouselNav,
  whoIsFor,
  whoIsForTop,
  HowItWorks,
  errorIcon,
  warningIcon,
  infoIcon,
  successIcon,
  loginBg,
  signOut,
  perValidationEmail,
  validationSuccess,
  underConstruction,
};
