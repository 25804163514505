// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CAROUSEL SECTION STARTS HERE */
.slick-dots {
  bottom: -60px!important;
}
.slick-dots li {
  margin: 0px 2px!important;
}
.slick-dots li *, .slick-dots li {
  width: auto!important;
  height: auto!important;
  padding: 0!important;
}
.slick-dots li button:before {
  width: auto!important;
  height: auto!important;
  position: inherit!important;
  line-height: normal!important;
  color: #D9D9D9!important;
  opacity: 1!important;
  font-size: 8px!important;
}
.slick-dots li.slick-active button:before {
  color: #FF9DCA!important;
}
.slick-arrow.slick-disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}
/* CAROUSEL SECTION ENDS HERE */`, "",{"version":3,"sources":["webpack://./src/css/Carousel.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;EACE,uBAAuB;AACzB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,oBAAoB;AACtB;AACA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,2BAA2B;EAC3B,6BAA6B;EAC7B,wBAAwB;EACxB,oBAAoB;EACpB,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EAEE,uBAAuB;EACvB,mBAAmB;AACrB;AACA,+BAA+B","sourcesContent":["/* CAROUSEL SECTION STARTS HERE */\n.slick-dots {\n  bottom: -60px!important;\n}\n.slick-dots li {\n  margin: 0px 2px!important;\n}\n.slick-dots li *, .slick-dots li {\n  width: auto!important;\n  height: auto!important;\n  padding: 0!important;\n}\n.slick-dots li button:before {\n  width: auto!important;\n  height: auto!important;\n  position: inherit!important;\n  line-height: normal!important;\n  color: #D9D9D9!important;\n  opacity: 1!important;\n  font-size: 8px!important;\n}\n.slick-dots li.slick-active button:before {\n  color: #FF9DCA!important;\n}\n.slick-arrow.slick-disabled {\n  -webkit-filter: grayscale(100%);\n  filter: grayscale(100%);\n  cursor: not-allowed;\n}\n/* CAROUSEL SECTION ENDS HERE */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
