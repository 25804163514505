// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SELECT SECTION STARTS HERE */
.mainlng-wrp {
  position: relative;
}
.mainlng-wrp.mobileview {
  margin-left: 10px;
  position: inherit;
}
.mainlng-wrp.mobileview .MuiButtonBase-root {
  padding-top: 0px;
}
.mainlng-wrp.mobileview>.MuiPaper-root {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #FCFAF6;
  z-index: 1;
  height: 100%;
  border-bottom-left-radius: 0;
}
.mainlng-wrp.mobileview .MuiList-root {
  height:calc(100% - 125px);
}
.selected-lngcode {
  color: #08090F;
  font-family: 'Bayon', sans-serif;
  font-size: 18px;
  position: relative;
  top: 1px;
  text-transform: uppercase;
}
/* SELECT SECTION ENDS HERE */`, "",{"version":3,"sources":["webpack://./src/css/SelectDropdown.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,MAAM;EACN,OAAO;EACP,QAAQ;EACR,WAAW;EACX,yBAAyB;EACzB,UAAU;EACV,YAAY;EACZ,4BAA4B;AAC9B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,gCAAgC;EAChC,eAAe;EACf,kBAAkB;EAClB,QAAQ;EACR,yBAAyB;AAC3B;AACA,6BAA6B","sourcesContent":["/* SELECT SECTION STARTS HERE */\n.mainlng-wrp {\n  position: relative;\n}\n.mainlng-wrp.mobileview {\n  margin-left: 10px;\n  position: inherit;\n}\n.mainlng-wrp.mobileview .MuiButtonBase-root {\n  padding-top: 0px;\n}\n.mainlng-wrp.mobileview>.MuiPaper-root {\n  top: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  background-color: #FCFAF6;\n  z-index: 1;\n  height: 100%;\n  border-bottom-left-radius: 0;\n}\n.mainlng-wrp.mobileview .MuiList-root {\n  height:calc(100% - 125px);\n}\n.selected-lngcode {\n  color: #08090F;\n  font-family: 'Bayon', sans-serif;\n  font-size: 18px;\n  position: relative;\n  top: 1px;\n  text-transform: uppercase;\n}\n/* SELECT SECTION ENDS HERE */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
