// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CONTACT US PAGE */
.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0!important;
}
/* CONTACT US PAGE */`, "",{"version":3,"sources":["webpack://./src/css/Contactus.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,wBAAwB;AAC1B;AACA,oBAAoB","sourcesContent":["/* CONTACT US PAGE */\n.MuiAccordionSummary-content.Mui-expanded {\n  margin: 12px 0!important;\n}\n/* CONTACT US PAGE */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
