// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiStepIcon-root.Mui-active {
    color: rgba(31, 197, 130, 0.60)!important;
}
.MuiStepIcon-root.Mui-completed {
    color: #1FC582!important;
}
.MuiStepLabel-label {
    color: black;
    font-family: 'Harmattan', sans-serif!important;
    font-size: 18px!important;
}
.MuiStepIcon-text {
    font-family: 'Harmattan', sans-serif!important;
    font-size: 16px!important;
    line-height: normal;
}
.MuiSwitch-track {
    background-color: #000000!important;
    opacity: 0.38!important;
}
.Mui-checked+.MuiSwitch-track {
    background-color: #1FC582!important;
    opacity: 0.5!important;
}
.Mui-checked .MuiTouchRipple-root, .Mui-checked.MuiSwitch-switchBase {
   color: #1FC582!important;
}
.Mui-checked.MuiSwitch-switchBase:hover {
    background-color: rgba(30, 197, 130, 4%)!important;
}
.MuiSwitch-thumb {
    color: #fff!important;
    box-shadow: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20));
}`, "",{"version":3,"sources":["webpack://./src/css/Stepper.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;AAC7C;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,YAAY;IACZ,8CAA8C;IAC9C,yBAAyB;AAC7B;AACA;IACI,8CAA8C;IAC9C,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,mCAAmC;IACnC,uBAAuB;AAC3B;AACA;IACI,mCAAmC;IACnC,sBAAsB;AAC1B;AACA;GACG,wBAAwB;AAC3B;AACA;IACI,kDAAkD;AACtD;AACA;IACI,qBAAqB;IACrB,kJAAkJ;AACtJ","sourcesContent":[".MuiStepIcon-root.Mui-active {\n    color: rgba(31, 197, 130, 0.60)!important;\n}\n.MuiStepIcon-root.Mui-completed {\n    color: #1FC582!important;\n}\n.MuiStepLabel-label {\n    color: black;\n    font-family: 'Harmattan', sans-serif!important;\n    font-size: 18px!important;\n}\n.MuiStepIcon-text {\n    font-family: 'Harmattan', sans-serif!important;\n    font-size: 16px!important;\n    line-height: normal;\n}\n.MuiSwitch-track {\n    background-color: #000000!important;\n    opacity: 0.38!important;\n}\n.Mui-checked+.MuiSwitch-track {\n    background-color: #1FC582!important;\n    opacity: 0.5!important;\n}\n.Mui-checked .MuiTouchRipple-root, .Mui-checked.MuiSwitch-switchBase {\n   color: #1FC582!important;\n}\n.Mui-checked.MuiSwitch-switchBase:hover {\n    background-color: rgba(30, 197, 130, 4%)!important;\n}\n.MuiSwitch-thumb {\n    color: #fff!important;\n    box-shadow: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
