// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ABOUT PAGE */
.productlist .MuiCardMedia-root {
  object-fit: contain;
}
/* ABOUT PAGE */`, "",{"version":3,"sources":["webpack://./src/css/Aboutus.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,mBAAmB;AACrB;AACA,eAAe","sourcesContent":["/* ABOUT PAGE */\n.productlist .MuiCardMedia-root {\n  object-fit: contain;\n}\n/* ABOUT PAGE */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
